<template>
  <section class="basic-textarea">
    <b-row>
      <b-col cols="12" v-if="isMasterCompany">
        <b-card>
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <b-form-group :label="$t('company.transaction_list.company')" label-for="company">
                <treeselect id="company" v-model="selectedCompany" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="subCompanies" :placeholder="$t('company.transaction_list.company_placeholder')" @select="getCompanyTransactions" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="balance">
        <b-card>
          <b-row>
            <b-col cols="12" md="4" xl="3">
              <b-card :bg-variant="balance.TOTAL_DEBIT > 0 ? 'light-danger' : 'light-secondary'" class="m-md-0">
                <b-card-text class="balance-card-title">
                  {{ $t('company.transaction_list.total_debit') }}
                </b-card-text>
                <b-card-text class="balance-card-amount">
                  {{ priceFormat(balance.TOTAL_DEBIT) }}
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="12" md="4" xl="3">
              <b-card :bg-variant="balance.TOTAL_CREDIT > 0 ? 'light-success' : 'light-secondary'" class="m-md-0">
                <b-card-text class="balance-card-title">
                  {{ $t('company.transaction_list.total_credit') }}
                </b-card-text>
                <b-card-text class="balance-card-amount">
                  {{ priceFormat(balance.TOTAL_CREDIT) }}
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="12" md="4" xl="3">
              <b-card :bg-variant="balanceClass(balance.BALANCE)" class="m-md-0">
                <b-card-text class="balance-card-title">
                  {{ $t('company.transaction_list.balance') }}
                </b-card-text>
                <b-card-text class="balance-card-amount">
                  {{ priceFormat(balance.BALANCE) }}
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" v-show="!isMasterCompany || (isMasterCompany && selectedCompany)">
        <b-overlay :show="!tableReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <b-card>
            <div v-if="tableData.length">
              <table id="TransactionList" class="dataTable table">
                <thead>
                  <tr>
                    <th>{{ $t('company.transaction_list.DOC_DATE') }}</th>
                    <th>{{ $t('company.transaction_list.DUE_DATE') }}</th>
                    <th>{{ $t('company.transaction_list.TRA_NAME') }}</th>
                    <th>{{ $t('company.transaction_list.SOURCE_APP') }}</th>
                    <th>{{ $t('company.transaction_list.SC') }}</th>
                    <th class="text-right">
                      {{ $t('company.transaction_list.DEBIT') }}
                    </th>
                    <th class="text-right">
                      {{ $t('company.transaction_list.CREDIT') }}
                    </th>
                    <th class="text-right">
                      {{ $t('company.transaction_list.RUNNING_TOTAL') }}
                    </th>
                    <th class="text-center">
                      {{ $t('company.transaction_list.STATE') }}
                    </th>
                    <th>{{ $t('company.transaction_list.CUR_CODE') }}</th>
                    <th class="text-right">
                      {{ $t('company.transaction_list.CUR_RATE') }}
                    </th>
                    <th class="text-right">
                      {{ $t('company.transaction_list.DEBIT_CUR') }}
                    </th>
                    <th class="text-right">
                      {{ $t('company.transaction_list.CREDIT_CUR') }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData" :key="item.company_id">
                    <td :data-order="dateTimeStemp(item.DOC_DATE)">
                      {{ showDate(item.DOC_DATE) }}
                    </td>
                    <td :data-order="dateTimeStemp(item.DOC_DATE)">{{ showDate(item.DUE_DATE) }}</td>
                    <td>{{ item.TRA_NAME }}</td>
                    <td>{{ item.SOURCE_APP }}</td>
                    <td>{{ item.SC }}</td>
                    <td class="text-right text-nowrap">
                      {{ priceTL(item.DEBIT) }}
                    </td>
                    <td class="text-right text-nowrap">
                      {{ priceTL(item.CREDIT) }}
                    </td>
                    <td class="text-right text-white text-nowrap font-weight-bold" :class="bgVariant(item.STATE)">
                      {{ priceTL(item.RUNNING_TOTAL) }}
                    </td>
                    <td class="text-center">
                      <b-badge :variant="stateVariant(item.STATE)">
                        {{ $t('company.transaction_list.state_' + item.STATE) }}
                      </b-badge>
                    </td>
                    <td>{{ item.CUR_CODE }}</td>
                    <td class="text-right text-nowrap">
                      {{ priceTL(item.CUR_RATE) }}
                    </td>
                    <td class="text-right text-nowrap">
                      {{ priceDebit(item) }}
                    </td>
                    <td class="text-right text-nowrap">
                      {{ priceCredit(item) }}
                    </td>
                    <td class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-alert variant="danger" :show="tableReady" v-else>
              <h4 class="alert-heading d-flex justify-content-between">
                <div>
                  {{ $t('Company Transaction') }}
                </div>
                <b-button variant="none" size="sm" @click="getCompanyData()">
                  <i class="fal fa-sync-alt text-danger" />
                </b-button>
              </h4>
              <div class="alert-body">
                <span>
                  {{ $t('notifications.get_company_transaction_not_found') }}
                </span>
              </div>
            </b-alert>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<style lang="scss" scoped>
.balance-card-title,
.balance-card-amount {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
}
</style>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardText, BCardBody, BDropdown, BDropdownItem, BLink, BOverlay, BBadge, BAlert, BButton, BFormGroup } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'
import '@/assets/js/functions/datatables.settings'
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { GET_ALL_COMPANY, GET_BALANCE, GET_COMPANY_BALANCE, GET_COMPANY_TRANSACTION, GET_COMPANY_TRANSACTION_LIST } from '@/store/services/company-service'
import moment from 'moment'
import { priceFormat } from '@/assets/js/functions/currency-functions'

export default {
  name: 'company-transaction-list',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BLink,
    BOverlay,
    BBadge,
    BAlert,
    BButton,
    BFormGroup,
    Treeselect
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: false,
      selectedCompany: undefined,
      subCompanies: undefined,
      balance: undefined
    }
  },
  computed: {
    ...mapGetters(['getLocale', 'currentUser', 'isMasterCompany'])
  },
  created() {
    if (this.isMasterCompany) {
      this.getAllCompanies()
    } else {
      this.getCompanyData()
    }
  },
  methods: {
    getCompanyData() {
      this.tableReady = false
      this.tableData = []
      this.balance = undefined
      this.$store
        .dispatch(GET_COMPANY_TRANSACTION)
        .then(response => {
          this.tableData = response
          this.$nextTick(() => {
            this.createTable()
          })
        })
        .catch(() => {
          this.tableReady = true
        })
      this.$store.dispatch(GET_BALANCE).then(response => {
        this.balance = response
      })
    },
    createTable() {
      this.table = $('#TransactionList').DataTable({
        order: [0, 'desc'],
        columnDefs: [
          {
            orderable: false,
            targets: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
          }
        ],
        scrollX: true,
        scrollY: '1000px',
        scrollCollapse: true,
        buttons: [
          {
            extend: 'colvis',
            text: this.$t('dataTable.show_hide')
          },
          {
            extend: 'pdf',
            title: `${this.currentUser.company.company_trade_name}
            ${this.$t('Company Transaction')}
            ${moment().format('DD.MM.YYYY')}`,
            filename: `${this.currentUser.company.company_trade_name}
            ${this.$t('Company Transaction')} 
            ${moment().format('DD.MM.YYYY')}`,
            orientation: 'landscape',
            pageSize: 'LEGAL'
          },
          {
            extend: 'excel',
            title: `${this.currentUser.company.company_trade_name}
            ${this.$t('Company Transaction')}
            ${moment().format('DD.MM.YYYY')}`,
            filename: `${this.currentUser.company.company_trade_name}
            ${this.$t('Company Transaction')}
            ${moment().format('DD.MM.YYYY')}`
          }
        ],
        language: {
          url: this.getLocale == 'tr-TR' ? '//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json' : '//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json'
        }
      })
      this.tableReady = true
    },
    showDate(date) {
      return new Intl.DateTimeFormat(this.getLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }).format(new Date(date))
    },
    priceTL(amount) {
      return priceFormat(Math.abs(amount), this.getLocale, 'TRY')
    },
    itemCur(item) {
      let temp = {}
      temp[item.CUR_CODE] = item.CUR_RATE
      return temp
    },
    priceDebit(item) {
      return priceFormat(item.DEBIT_CUR, this.getLocale, item.CUR_CODE)
    },
    priceCredit(item) {
      return priceFormat(item.CREDIT_CUR, this.getLocale, item.CUR_CODE)
    },
    stateVariant(state) {
      switch (state) {
        case 0:
          return 'light-danger'
        case 1:
          return 'light-success'
        case 2:
          return 'light-secondary'
      }
    },
    bgVariant(state) {
      switch (state) {
        case 0:
          return 'bg-light-danger'
        case 1:
          return 'bg-light-success'
        case 2:
          return 'bg-light-secondary'
      }
    },
    reDraw() {
      Promise.all([this.table.destroy()]).then(this.createTable)
    },
    getAllCompanies() {
      return new Promise(resolve => {
        this.subCompanies = []
        this.$store.dispatch(GET_ALL_COMPANY).then(response => {
          response.forEach(item => {
            this.subCompanies.push({
              id: item.company_id,
              label: item.company_name
            })
          })
          resolve()
        })
      })
    },
    getCompanyTransactions(node) {
      this.tableReady = false
      this.tableData = []
      this.balance = undefined
      this.$store
        .dispatch(GET_COMPANY_TRANSACTION_LIST, node.id)
        .then(response => {
          this.tableData = response
          this.$nextTick(() => {
            this.createTable()
          })
        })
        .catch(() => {
          this.tableReady = true
        })
      this.$store.dispatch(GET_COMPANY_BALANCE, node.id).then(response => {
        this.balance = response
      })
    },
    priceFormat(amount) {
      let temp = Math.abs(amount)
      return priceFormat(temp, this.getLocale, 'TRY')
    },
    balanceClass(amount) {
      if (amount > 0) {
        return 'light-success'
      } else if (amount < 0) {
        return 'light-danger'
      } else {
        return 'light-secondary'
      }
    },
    dateTimeStemp(time) {
      return +moment(time).format('X')
    }
  },
  watch: {
    getLocale() {
      this.reDraw()
    },
    selectedCompany(val) {
      if (!val) {
        this.balance = undefined
      }
    }
  }
}
</script>
